<template>
    <b-overlay :show="showLoader">
        <RoundedButton v-if="showPreview" class="mb-2" :icon="true" :iconLeft="true" padding="11px 20px" @click="showPreview = false">
            <template v-slot:icon><FeatherIcon class="mr-1" icon="ChevronLeftIcon" size="2x"/></template>
            <template v-slot:name>Nazaj</template>
        </RoundedButton>
        <blue-background/>
        <div :class="showPreview ? 'd-none' : 'd-block'" style="background-color: white; padding: 1rem;">
            <div class="filter">
                1. Izberite kategorijo
            </div>
            <validation-observer ref="validate">
                <b-form class="form">
                    <b-form-group class="mt-1" title="Tip kategorije">
                        <b-row>
                            <b-col cols="12" sm="8" md="3">
                                <validation-provider #default="{ errors }" name="tip kategorije" rules="required">
                                    <Select v-model="object.listing_type" :options="typeCategorys" label="title" reduceField="id" :clearable="false" placeholder="Vrsta oglasa"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group title="Kategorija">
                        <validation-provider #default="{ errors }" name="kategorija" rules="required">
                            <div class="d-none d-md-flex flex-wrap">
                                <div class="radio" :key="item.id" v-for="item in categoriesOptions" :style="[object.category_id === item.id ? {'color': 'white', 'background-color': '#72A5D8'}:'']">
                                    {{item.title}}
                                    <label :for="'chech-' + item.id" class="check-label"/>
                                    <b-form-radio class="d-none"  :id="'chech-' + item.id" v-model="object.category_id" :value="item.id"/>
                                </div>
                            </div>

                            <div class="mt-2" style="font-size: 11px;">
                                *Za objavo nepremičninskega oglasa se obrnite na <span style="color: #72A5D8; ">info@esoseska.si</span>
                            </div>

                            <b-row>
                                <b-col cols="12" sm="8" md="3">
                                    <Select class="d-md-none" v-model="object.category_id" :options="categoriesOptions" label="title" reduceField="id" :clearable="false" placeholder="Kategorija"/>
                                </b-col>
                            </b-row>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <div class="filter mt-3">
                        2. Izpolnite podatke
                    </div>
                    <b-row class="mt-1">
                        <b-col cols="12" sm="8" md="3">
                            <validation-provider #default="{ errors }" name="stanje" rules="required">
                                <Select v-model="object.condition" :options="types" label="title" reduceField="id" :clearable="false" placeholder="Stanje"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' md='6'>
                            <hr style="border-top: 1px solid rgba(0, 0, 0, 0.7);">
                            <b-form-group>
                                <validation-provider #default="{ errors }" name="naslov oglasa" rules="required|max:100">
                                    <b-form-input id="title" v-model="object.title" placeholder="NASLOV OGLASA"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group>
                                <validation-provider #default="{ errors }" name="opis" rules="required|max:5000">
                                    <TextAreaEditor v-model="object.content" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group v-if="object.listing_type !== 4">
                                <validation-provider #default="{ errors }" name="cena" rules="required|max_value:10000000|min_value:0">
                                    <b-row align-v="center">
                                        <b-col cols="12" sm="6">
                                            <b-input-group class="input-group-merge" append="€">
                                                <b-form-input step="0.01" type="number" :value="price" v-on:input="changePrice" placeholder="CENA"/>
                                            </b-input-group>
                                        </b-col>
                                        <b-col cols="12" sm="5" class="text-left mt-1 mt-sm-0">
                                            <RadioField name="agreement" v-model="object.price_offer" :value="true" text="Po dogovoru"/>
                                        </b-col>
                                    </b-row>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group>
                                <b-row align-v="center">
                                    <b-col cols="12" lg="6">
                                        <validation-provider #default="{ errors }" name="regija" rules="required|max:30">
                                            <b-form-input id="regija" v-model="object.region" placeholder="Regija"/>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" lg="6" class="text-left mt-1 mt-lg-0">
                                        <validation-provider #default="{ errors }" name="kraj" rules="required|max:30">
                                            <b-form-input id="kraj" v-model="object.town" placeholder="Kraj"/>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <div class="my-2" style="font-size: 20px; font-weight: bold;">Kontaktni podatki</div>
                            <b-form-group>
                                <b-form-input id="name" readonly v-model="object.name" placeholder="IME (PREDIZPOLNJENO)"/>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input id="email" readonly v-model="object.email" placeholder="E-NASLOV (PREDIZPOLNJENO)"/>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input id="phone" readonly v-model="object.telephone_number" placeholder="KONTAKTNA ŠTEVILKA (PREDIZPOLNJENO)"/>
                            </b-form-group>
                            <b-form-group class="pt-1">
                                <div v-if="!terms" style="background-color: #FF4307; position: relative">
                                    <p class="text-white" style="padding: 5px;">Pred oddajo oglasa se moraš strinjati s splošnimi pogoji eSoseska.si.</p>
                                    <div style="position: absolute; top:90%; left: 0; border-top: 15px solid #FF4307; border-right: 15px solid transparent;"/>
                                </div>
                                <div style="padding-top: 0.5rem;">
                                    <validation-provider #default="{ errors }" name="pogoji" :rules="{ required: { allowFalse: false } }">
                                        <checkbox-field name="terms" v-model="terms">Strinjam se s <a href="/pogoji-poslovanja" target="_blank" style="font-weight: bold;">pogoji</a> uporabe eSoseska.</checkbox-field>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                            </b-form-group>
                            <b-form-group class="mt-2">
                                <b-row cols="m-0">
                                    <b-col cols="12" lg="6">
                                        <RoundedButton class="preview-button m-0 p-2" style="height: 52px;"  :icon="true" :iconLeft="true" :block="true" :paddingIcon="1" padding="12px 22px" @click="preview">
                                            <template v-slot:icon><img :src="require('@/assets/svg/eye.svg')"/></template>
                                            <template v-slot:name>Predogled oglasa</template>
                                        </RoundedButton>
                                    </b-col>
                                    <b-col cols="12" lg="6" class="mt-2 mt-lg-0">
                                        <RoundedButton @click="validationForm" style="height: 52px;" padding="12px 22px" :block="true">
                                            Oddaj oglas
                                        </RoundedButton>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                        <b-col cols='12' md='6' class="photos">
                            <ImageSelector ref="imageSelector" :main_photo="object.main_photo" :additional_photos="object.additional_photos" @update-main-photo="updateMainPhoto" @update-preview-photos="updatePreviewPhotos"/>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </div>
        <ModalAddConfirmation ref="modalAddConf"/>
        <classified-ad-preview v-if="showPreview" :object="object" :previewPhotos="previewPhotos"/>
    </b-overlay>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, maxValue, minValue, max } from '@validations'
    import TextAreaEditor from '@/views/Components/TextAreaEditor'
    import RadioField from '@/views/Components/RadioField'
    import ImageSelector from '@/views/Components/ImageSelector'
    import ClassifiedAdPreview from '@/views/Pages/C2C/ClassifiedAdPreview'
    import {BOverlay, BRow, BCol, BForm, BFormGroup, BFormRadio, BFormInput, BInputGroup, VBToggle} from 'bootstrap-vue'
    import Vue from 'vue'
    import ModalAddConfirmation from '../../Components/ModalAddConfirmation'
    import Select from '@/views/Components/Select'
    import RoundedButton from '@/views/Components/RoundedButton'
    import BlueBackground from '@/views/Components/BlueBackground'
    import CheckboxField from '@/views/Components/CheckboxField'
    export default {
        components: {
            CheckboxField,
            BlueBackground,
            RoundedButton,
            ValidationProvider,
            ValidationObserver,
            TextAreaEditor,
            RadioField,
            ImageSelector,
            ClassifiedAdPreview,
            BOverlay,
            BRow,
            BCol,
            BForm,
            BFormGroup,
            BFormRadio,
            BFormInput,
            BInputGroup,
            ModalAddConfirmation,
            Select
        },
        data() {
            return {
                object: {
                    author_id: '',
                    date_posted: '',
                    category_id: '',
                    approved: false,
                    title: '',
                    content: '',
                    listing_type: '',
                    condition: '',
                    price: '',
                    price_offer:false,
                    name: '',
                    emailPost: '',
                    phone: '',
                    additional_photos:[],
                    region: '',
                    town : '',
                    main_photo: ''
                },
                typeCategorys: [
                    {id: 0, title: 'PRODAM'},
                    {id: 1, title: 'KUPIM'},
                    {id: 2, title: 'ZAMENJAM'},
                    {id: 3, title: 'ODDAM'},
                    {id: 4, title: 'PODARIM'}
                ],
                types: [
                    {id: 0, title: 'NOVO'},
                    {id: 1, title: 'RABLJENO'},
                    {id: 2, title: 'POŠKODOVANO'}
                ],
                categoriesOptions: [],
                selectedCategory: false,
                currentActiveCategory: null,
                required,
                email,
                maxValue,  
                minValue,
                max,
                showLoader: false,
                coll: false,
                showPreview : false,
                previewPhotos: {main_photo: null, additional_photos: []},
                price : 0,
                terms: false
            }
        },
        methods: {
            validationForm() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        this.addClassifiedAd()
                    }
                })
            },
            changePrice(val) {
                this.price = val
                this.object.price = Number(Number(val * 100).toFixed(0))
            },
            updateMainPhoto(photo) {
                this.object.main_photo = photo
            },
            updatePreviewPhotos(photo, photos) {
                this.previewPhotos.main_photo = photo
                this.previewPhotos.additional_photos = photos
            },
            async addClassifiedAd() {

                const thisIns = this
                thisIns.showLoader = true

                try {
                    
                    const imageUploadResult = await this.$refs.imageSelector.UploadImagesToAPI()
                    this.showLoader = true
                    if (this.object.main_photo === '') {
                        this.$printWarning('Potrebno je dodati vsaj eno fotografijo')
                        this.showLoader = false
                        return
                    }

                    if (imageUploadResult) {
                        this.object.date_posted = new Date()
                        this.object.author_id = ''
                        
                        await this.$http.put('/api/user/v1/c2c/', thisIns.object)
                
                        thisIns.$printSuccess('Oglas je dodan')
                        this.$refs.modalAddConf.open()
                   
                    } else {
                        thisIns.$printError('Prišlo je do napake pri nalaganju slik. Prosim poskusite ponovno!')
                    }
                } catch (error) {
                    this.$printError(error.response.data)
                        
                } finally {
                    this.showLoader = false
                }
            },
            getCategories() {
                const thisIns = this
                thisIns.dataLoaded = false
                thisIns.categoriesOptions = []

                const loadPromise = this.$http.get('/api/user/v1/c2c/category/')
                loadPromise.then(function(response) {
                    if (response.data.length > 0) {
                        for (const item of response.data) {
                            if (item.children.length > 0) {
                                for (const children of item.children) thisIns.categoriesOptions.push(children)
                            }
                        }
                    }
                    thisIns.dataLoaded = true
                }).catch(function(error) {
                    thisIns.dataLoaded = true
                    thisIns.$printError(error.response.data)
                })
            },
            preview() {
                this.showPreview = true
            }
        },
        created() {
            Vue.directive('b-toggle', VBToggle)

            if (this.$store.state.user.userData !== null) {
                this.object.name = `${this.$store.state.user.userData.name  } ${  this.$store.state.user.userData.surname}`
                this.object.email = this.$store.state.user.userData.email
                this.object.telephone_number = this.$store.state.user.userData.telephone_number
            }
            this.getCategories()
        },
        watch: {
            'price'(val) {
                if (val > 0) this.object.price_offer = false
                else if (val === 0) this.object.price_offer = true
            },
            'object.price_offer'(val) {
                if (val) {
                    this.price = 0
                    this.object.price = 0
                }
            },
            'object.listing_type'(val) {
                if (val === 4) {
                    this.object.price_offer = true
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            if (!this.showPreview) {
                next()
            } else {
                this.showPreview = false
                next(false)
            }
        }
    }
</script>

<style scoped>
.filter {
  background-color: #72A5D8;
  padding: 1rem;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
}
.radio {
  position: relative;
  border: 2px solid #72A5D8;
  box-sizing: border-box;
  border-radius: 42px;
  padding: 1rem;
  color: #72A5D8;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  margin: 0.5rem;
}
.check-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 42px;
  cursor: pointer;
}
.form-control, .input-group-text {
  border-radius: 0 !important;
  border: none !important;
  background-color: #E7E6E6;
  color: black;
  font-weight: 400;
  font-size: 17px;
}
input::placeholder {
  color: black !important;
  text-transform: uppercase;
}
input:focus, .form-control:focus + .input-group-append .input-group-text {
  background-color: white;
  border-color: white;
  box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1);
}
input:read-only:focus {
  background-color: #E7E6E6;
}
.preview-button {
  color: black !important;
  background-color: #E7E6E6 !important;
  border-color: #E7E6E6 !important;
}
.preview-button:hover {
  box-shadow: 0 8px 25px -8px #E7E6E6 !important;
}
.input-group-text {
  border: none !important;
}
@media only screen and (max-width: 720px) {
  .photos {
    margin-top: 10px;
  }
}
</style>