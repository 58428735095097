var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[(_vm.showPreview)?_c('RoundedButton',{staticClass:"mb-2",attrs:{"icon":true,"iconLeft":true,"padding":"11px 20px"},on:{"click":function($event){_vm.showPreview = false}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FeatherIcon',{staticClass:"mr-1",attrs:{"icon":"ChevronLeftIcon","size":"2x"}})]},proxy:true},{key:"name",fn:function(){return [_vm._v("Nazaj")]},proxy:true}],null,false,1845438570)}):_vm._e(),_c('blue-background'),_c('div',{class:_vm.showPreview ? 'd-none' : 'd-block',staticStyle:{"background-color":"white","padding":"1rem"}},[_c('div',{staticClass:"filter"},[_vm._v(" 1. Izberite kategorijo ")]),_c('validation-observer',{ref:"validate"},[_c('b-form',{staticClass:"form"},[_c('b-form-group',{staticClass:"mt-1",attrs:{"title":"Tip kategorije"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8","md":"3"}},[_c('validation-provider',{attrs:{"name":"tip kategorije","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.typeCategorys,"label":"title","reduceField":"id","clearable":false,"placeholder":"Vrsta oglasa"},model:{value:(_vm.object.listing_type),callback:function ($$v) {_vm.$set(_vm.object, "listing_type", $$v)},expression:"object.listing_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"title":"Kategorija"}},[_c('validation-provider',{attrs:{"name":"kategorija","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-none d-md-flex flex-wrap"},_vm._l((_vm.categoriesOptions),function(item){return _c('div',{key:item.id,staticClass:"radio",style:([_vm.object.category_id === item.id ? {'color': 'white', 'background-color': '#72A5D8'}:''])},[_vm._v(" "+_vm._s(item.title)+" "),_c('label',{staticClass:"check-label",attrs:{"for":'chech-' + item.id}}),_c('b-form-radio',{staticClass:"d-none",attrs:{"id":'chech-' + item.id,"value":item.id},model:{value:(_vm.object.category_id),callback:function ($$v) {_vm.$set(_vm.object, "category_id", $$v)},expression:"object.category_id"}})],1)}),0),_c('div',{staticClass:"mt-2",staticStyle:{"font-size":"11px"}},[_vm._v(" *Za objavo nepremičninskega oglasa se obrnite na "),_c('span',{staticStyle:{"color":"#72A5D8"}},[_vm._v("info@esoseska.si")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8","md":"3"}},[_c('Select',{staticClass:"d-md-none",attrs:{"options":_vm.categoriesOptions,"label":"title","reduceField":"id","clearable":false,"placeholder":"Kategorija"},model:{value:(_vm.object.category_id),callback:function ($$v) {_vm.$set(_vm.object, "category_id", $$v)},expression:"object.category_id"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"filter mt-3"},[_vm._v(" 2. Izpolnite podatke ")]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","sm":"8","md":"3"}},[_c('validation-provider',{attrs:{"name":"stanje","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.types,"label":"title","reduceField":"id","clearable":false,"placeholder":"Stanje"},model:{value:(_vm.object.condition),callback:function ($$v) {_vm.$set(_vm.object, "condition", $$v)},expression:"object.condition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('hr',{staticStyle:{"border-top":"1px solid rgba(0, 0, 0, 0.7)"}}),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"naslov oglasa","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"NASLOV OGLASA"},model:{value:(_vm.object.title),callback:function ($$v) {_vm.$set(_vm.object, "title", $$v)},expression:"object.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"opis","rules":"required|max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaEditor',{model:{value:(_vm.object.content),callback:function ($$v) {_vm.$set(_vm.object, "content", $$v)},expression:"object.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.object.listing_type !== 4)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"cena","rules":"required|max_value:10000000|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"step":"0.01","type":"number","value":_vm.price,"placeholder":"CENA"},on:{"input":_vm.changePrice}})],1)],1),_c('b-col',{staticClass:"text-left mt-1 mt-sm-0",attrs:{"cols":"12","sm":"5"}},[_c('RadioField',{attrs:{"name":"agreement","value":true,"text":"Po dogovoru"},model:{value:(_vm.object.price_offer),callback:function ($$v) {_vm.$set(_vm.object, "price_offer", $$v)},expression:"object.price_offer"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3877299161)})],1):_vm._e(),_c('b-form-group',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"regija","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"regija","placeholder":"Regija"},model:{value:(_vm.object.region),callback:function ($$v) {_vm.$set(_vm.object, "region", $$v)},expression:"object.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"text-left mt-1 mt-lg-0",attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"kraj","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kraj","placeholder":"Kraj"},model:{value:(_vm.object.town),callback:function ($$v) {_vm.$set(_vm.object, "town", $$v)},expression:"object.town"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"my-2",staticStyle:{"font-size":"20px","font-weight":"bold"}},[_vm._v("Kontaktni podatki")]),_c('b-form-group',[_c('b-form-input',{attrs:{"id":"name","readonly":"","placeholder":"IME (PREDIZPOLNJENO)"},model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}})],1),_c('b-form-group',[_c('b-form-input',{attrs:{"id":"email","readonly":"","placeholder":"E-NASLOV (PREDIZPOLNJENO)"},model:{value:(_vm.object.email),callback:function ($$v) {_vm.$set(_vm.object, "email", $$v)},expression:"object.email"}})],1),_c('b-form-group',[_c('b-form-input',{attrs:{"id":"phone","readonly":"","placeholder":"KONTAKTNA ŠTEVILKA (PREDIZPOLNJENO)"},model:{value:(_vm.object.telephone_number),callback:function ($$v) {_vm.$set(_vm.object, "telephone_number", $$v)},expression:"object.telephone_number"}})],1),_c('b-form-group',{staticClass:"pt-1"},[(!_vm.terms)?_c('div',{staticStyle:{"background-color":"#FF4307","position":"relative"}},[_c('p',{staticClass:"text-white",staticStyle:{"padding":"5px"}},[_vm._v("Pred oddajo oglasa se moraš strinjati s splošnimi pogoji eSoseska.si.")]),_c('div',{staticStyle:{"position":"absolute","top":"90%","left":"0","border-top":"15px solid #FF4307","border-right":"15px solid transparent"}})]):_vm._e(),_c('div',{staticStyle:{"padding-top":"0.5rem"}},[_c('validation-provider',{attrs:{"name":"pogoji","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('checkbox-field',{attrs:{"name":"terms"},model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}},[_vm._v("Strinjam se s "),_c('a',{staticStyle:{"font-weight":"bold"},attrs:{"href":"/pogoji-poslovanja","target":"_blank"}},[_vm._v("pogoji")]),_vm._v(" uporabe eSoseska.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',{staticClass:"mt-2"},[_c('b-row',{attrs:{"cols":"m-0"}},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('RoundedButton',{staticClass:"preview-button m-0 p-2",staticStyle:{"height":"52px"},attrs:{"icon":true,"iconLeft":true,"block":true,"paddingIcon":1,"padding":"12px 22px"},on:{"click":_vm.preview},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require('@/assets/svg/eye.svg')}})]},proxy:true},{key:"name",fn:function(){return [_vm._v("Predogled oglasa")]},proxy:true}])})],1),_c('b-col',{staticClass:"mt-2 mt-lg-0",attrs:{"cols":"12","lg":"6"}},[_c('RoundedButton',{staticStyle:{"height":"52px"},attrs:{"padding":"12px 22px","block":true},on:{"click":_vm.validationForm}},[_vm._v(" Oddaj oglas ")])],1)],1)],1)],1),_c('b-col',{staticClass:"photos",attrs:{"cols":"12","md":"6"}},[_c('ImageSelector',{ref:"imageSelector",attrs:{"main_photo":_vm.object.main_photo,"additional_photos":_vm.object.additional_photos},on:{"update-main-photo":_vm.updateMainPhoto,"update-preview-photos":_vm.updatePreviewPhotos}})],1)],1)],1)],1)],1),_c('ModalAddConfirmation',{ref:"modalAddConf"}),(_vm.showPreview)?_c('classified-ad-preview',{attrs:{"object":_vm.object,"previewPhotos":_vm.previewPhotos}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }