<template>
    <div @drop="checkDrop">
        <quill-editor ref="quill" v-model="model" :options="options"/>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import { quillEditor } from 'vue-quill-editor'

    export default {
        components: {
            quillEditor
        },
        props: {
            content: {
                type: String,
                required: false
            },
            value: {
                default: null
            }
        },
        data() {
            return {
                options: {
                    'placeholder': 'OPIS',
                    'modules': {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'], ['link', 'blockquote', 'code-block'], [{ header: [1, 2, 3, 4, 5, 6, false] }],   [{ 'script': 'sub'}, { 'script': 'super' }],
                            [{'indent': '-1'}, {'indent': '+1'}], [{'list': 'ordered'}, {'list': 'bullet'}], ['align', 'direction'], ['background', 'color', 'clean'] 
                        ]
                    }
                }
            }
        },
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    value = this.checkMatchImage(value)
                    value = this.checkMatchAudio(value)
                    value = this.checkMatchVideo(value)
                    value = this.checkMatchIframe(value)

                    this.$emit('input', value)
                }
            }
        },
        methods: {
            checkDrop(e) {
                //Kle se nek error pametn
                // this.$printWarning('Vstavljanje medijev ni dovoljeno!')
                e.preventDefault()
            },
            checkMatchVideo(value) {
                if (!value) return ''
                if (value.match(/<video .*?>/g)) {
                    this.$printWarning('Vstavljanje videa ni dovoljeno!')
                    return value.replace(/<video .*?>/g, '') 
                }
                return value
            },
            checkMatchAudio(value) {
                if (!value) return ''
                if (value.match(/<audio .*?>/g)) {
                    this.$printWarning('Vstavljanje audia ni dovoljeno!')
                    return value.replace(/<audio .*?>/g, '') 
                }
                return value
            },
            checkMatchImage(value) {
                if (!value) return ''
                if (value.match(/<img .*?>/g)) {
                    this.$printWarning('Vstavljanje slik ni dovoljeno!')
                    return value.replace(/<img .*?>/g, '') 
                }
                return value
            },
            checkMatchIframe(value) {
                if (!value) return ''
                if (value.match(/<iframe .*?>/g)) {
                    this.$printWarning('Vstavljanje iframe ni dovoljeno!')
                    return value.replace(/<iframe .*?>/g, '')
                }
                return value
            }
        }
    }
</script>
<style>
.ql-toolbar.ql-snow, .ql-container.ql-snow {
  border: none !important;
  background-color: #E7E6E6;
  color: black;
  font-weight: 400;
  font-size: 17px;
}
.ql-container.ql-snow {
  border-top: none;
}
.ql-editor {
  min-height: 200px;
}
.ql-editor:focus {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1);
}
.ql-formats {
  margin-right: 0 !important;
}
@media only screen and (min-width: 768px) {
  .ql-formats {
    margin-right: 1rem !important;
  }
}
</style>