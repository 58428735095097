<template>
    <b-modal class="sell-story" centered v-model="show" hide-header hide-footer no-close-on-backdrop>
        <div class="d-flex justify-content-center mt-1 mb-1">
            <h1>Oglas oddan</h1>
        </div>
        <p class="text-center">Po potrditvi bo oglas objavljen in videm vam, ter ostalim uporabnikom.</p>
        <div class="d-flex justify-content-center mt-2">
            <b-button @click="close">Zapri</b-button>
        </div>
    </b-modal>
</template>

<script>
    import {BModal, BButton} from 'bootstrap-vue'
    export default {
        components: {BModal, BButton},
        data() {
            return {
                show: false
            }
        },
        methods: {
            open() {
                this.show = true
            },
            close() {
                this.show = false
                this.$router.push({name: 'ad-list'})
            }
        }
    }
</script>

<style scoped>

</style>