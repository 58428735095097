<template>
    <div v-if="object">
        <b-row class="pt-1">
            <b-col cols="12" md="6">
                <div class="h-100">
                    <b-img v-if="previewPhotos.main_photo" class="main-photo" :src="preview(previewPhotos.main_photo)" fluid/>
                    <b-img v-else class="main-photo" :src="require('@/assets/images/esoseska/no-image.jpg')" fluid/>
                </div>
            </b-col>
            <b-col cols="12" md="6" class="mt-2 mt-md-0 d-flex flex-column">
                <h3 class="title">{{ object.title || 'Brez naslova' }}</h3>
                <div class="mt-1 mb-auto mb-2 d-flex flex-column">
                    <span class="c2c-description html-display" v-html="object.content"/>
                    <div class="mt-auto d-flex align-items-center justify-content-between">
                        <a  v-if="object.content.length > 0" @click="goto('description')">> preberi več</a>
                        <div class="date">objavljen: {{dayjs().format("DD.MM.YYYY")}}</div>
                    </div>
                </div>
                <div class="mt-auto">
                    <div class="p-1 d-flex flex-column h-100" style="background: #F3F3F3; -webkit-print-color-adjust: exact;">
                        <h2 style="font-weight: 400;font-size: 20px;line-height: 23px;">Cena:</h2>
                        <div class="mt-auto" v-if="object.listing_type !== 4">
                            <h1 v-if="object.price_offer" class="mb-0 price">PO DOGOVORU</h1>
                            <h1 v-else class="mb-0 price">{{$convertPriceIntegerToFloat(object.price)}} €</h1>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col cols="12" md="6">
                <div v-if="additionalPhotosUrls() && additionalPhotosUrls().length > 0">
                    <swiper :swiper-data="additionalPhotosUrls()" :pagination="false" type="images"/>
                </div>
            </b-col>
        </b-row>
        <div v-if="object" id="description" class="mt-2">
            <div class="c2c-description-all html-display" v-html="object.content"/>
        </div>
        <div class="mt-2 p-1" style="background: #72A5D8;">
            <div class="text-white" style="font-style: normal;font-weight: 700;font-size: 20px;line-height: 18px;">Kontaktirajte prodajalca</div>
        </div>
        <div class="py-4 px-2 px-md-4 contact-data text-center w-100" style="background: #F3F3F3;">
            <div>Ime: <span style="font-weight: bold;">{{ object.name }} {{ object.surname }}</span> <span style="font-size: 13px;">({{getManager()}} uporabnik)</span></div>
            <div class="mt-2">Regija: <span style="font-weight: bold;">{{ object.region || 'Ni podane regije' }}</span></div>
            <div>Kraj: <span style="font-weight: bold;">{{ object.town || 'Ni podane lokacije' }}</span></div>
            <div class="mt-2">Telefon: <span style="font-weight: bold;">{{ object.telephone_number || '/' }}</span></div>
            <div>Email: <span style="font-weight: bold;">{{ object.email || '/' }}</span></div>
        </div>
    </div>
</template>

<script>
    import Swiper from '../../Components/Swiper'
    import {BRow, BCol, BImg} from 'bootstrap-vue'
    export default {
        components: {
            Swiper,
            BRow,
            BCol,
            BImg
        },
        props: {
            object: {
                type: Object,
                default() {
                    return {
                        author_id: '',
                        date_posted: '',
                        category: '',
                        approved: false,
                        title: '',
                        content: '',
                        listing_type: '',
                        categories: [],
                        condition: 0,
                        price: '',
                        price_offer:false,
                        name: '',
                        emailPost: '',
                        phone: '',
                        additional_photos:[],
                        region: '',
                        town : '',
                        main_photo: ''
                    }
                }
            },
            previewPhotos: {
                type: Object,
                default() {
                    return {
                        main_photo: null,
                        additional_photos:[]
                    }
                }
            }
        },
        data() {
            return {
            }
        },
        methods: {
            preview(item) {
                if (typeof item !== 'string') {
                    return URL.createObjectURL(item)
                }
            },
            additionalPhotosUrls() {
                const photos = []
                for (const photo of this.previewPhotos.additional_photos) {
                    photos.push(this.preview(photo))
                }
                return photos
            },
            goto(id) {
                const element = document.getElementById(id)
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            },
            getManager() {
                if (this.$store.state.user.userData && this.$store.state.user.userData.manager !== '') {
                    if (this.$store.state.user.userData.manager === 'staninvest') return 'Staninvest'
                    else return 'Habit'
                }
            }
        },
        mounted() {
            this.$scrollToTop()
        }
    }
</script>


<style scoped>
a {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8 !important;
}
.price {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
}
.contact-data {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}
.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
}
.main-photo {
  max-height: 500px !important;
}
.c2c-description{
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.date {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #72A5D8;
}
@media only screen and (max-width: 500px) {
  .price{
    font-size: 25px !important;
  }
}
</style>
<style>
.c2c-description-all ul {
  padding-left: 20px !important;
}
</style>