<template>
    <div>
        <input ref="fileInput" type="file" class="d-none" multiple name="photos" @change="filesChange" accept=".jpg, .jpeg, .png, .gif, .webp"/>
        <div class="fileUp text-center position-relative">
            <div v-if="type !== 'edit'" class="fileUpIn">
                <b-row>
                    <b-col cols="10" lg="6" sm="8" md="8" offset="1" offset-lg="3" offset-sm="2" offset-md="2" class="p-1">
                        <div v-if="images.length === 0" class="img-add text-center my-5" @dragover.prevent @drop.prevent @drop="filesChangeDrop" @click="$refs.fileInput.click()">
                            <b-img fluid :src="require('@/assets/images/icons/addPhoto.svg')" style="max-width: 100px;" />
                            <div class="mt-1" style="font-size: 13px; line-height: 19px;">Povlecite fotografijo v označeno polje,<br>ali kliknite na ikonico “+” za nalaganje.</div>
                        </div>
                        <span v-else class="img-wrap">
                            <span class="tack"><fa style="transform: rotate(-45deg);" icon="thumbtack"/> Prikazna slika</span>
                            <b-img fluid :src="preview(imageFile)" />
                        </span>
                    </b-col>
                </b-row>
            </div>
            <div v-if="type === 'edit'" class="fileUpIn">
                <b-row>
                    <b-col cols="10" lg="6" sm="8" md="8" offset="1" offset-lg="3" offset-sm="2" offset-md="2" class="p-1">
                        <b-col v-if="images.length === 0 && additional_photos.length === 0" class="img-add text-center my-5" @dragover.prevent @drop.prevent @drop="filesChangeDrop" @click="$refs.fileInput.click()">
                            <b-img fluid :src="require('@/assets/images/icons/addPhoto.svg')" />
                            <div class="mt-1" style="font-size: 13px; line-height: 19px;">Povlecite fotografijo v označeno polje,<br>ali kliknite na ikonico “+” za nalaganje.</div>
                        </b-col>
                        <b-col v-else class="img-wrap w-100 h-100">
                            <span class="tack"><fa style="transform: rotate(-45deg);" icon="thumbtack"/> Prikazna slika</span>
                            <b-img v-if="localImage" fluid :src="preview(imageFile)" />
                            <b-img v-if="imageFile && !localImage" fluid :src="`/api/user/v1/image/${imageFile}`" />
                        </b-col>
                    </b-col>
                </b-row>
            </div>
            <div class="fileUpOut">

                <!-- NALAGANJE SLIK -->
                <b-row v-if="type !== 'edit'" class="text-center justify-content-center">

                    <!-- PREVIEW SLIK, ki so še lokalno -->
                    <b-col cols="4" md="4" lg="3"
                           :key="index" v-for="(item, index) in images"
                           class="image-card position-relative"
                           @click="selectMainPhoto($event)">

                        <span class="close" @click="deleteImage(index, item, true)"><b-img :src="require('@/assets/svg/circle-minus.svg')"/></span>
                        <b-img class="display-photo" style="width: 100%; height: 100%; object-fit: cover;" :src="preview(item)" @click="updatePhoto(item, true)"/>
                    </b-col>
                    <!-- modra slika dodaj -->
                    <b-col cols="12" md="4" lg="3" v-if="images.length > 0 && images.length <= 20" class="d-flex justify-content-center align-items-center image-card cursor-pointer" style="background: #EAF3F9;" @dragover.prevent @drop.prevent @drop="filesChangeDrop" @click="$refs.fileInput.click()">
                        <b-img class="display-photo" style="width: 40px; height: 40px;" :src="require('@/assets/svg/plus.svg')"/>
                    </b-col>
                </b-row>
                <b-row v-if="type === 'edit'" class="text-center justify-content-center">

                    <!-- Images are already uploaded to API -->
                    <b-col cols="4" md="4" lg="3"
                           :key="index"
                           v-for="(item, index) in additional_photos"
                           class="p-0 m-0 mx-1"
                           @click="selectMainPhoto($event)"
                    >
                        <span class="close" @click="deleteImage(index, item, false)"><b-img :src="require('@/assets/svg/circle-minus.svg')"/></span>
                        <b-img class="display-photo" style="width: 100%; height: 100%; object-fit: cover;" :src="'/api/user/v1/image/' + item" @click="updatePhoto(item, false)"/>
                    </b-col>

                    <b-col cols="12" md="4" lg="3"
                           :key="additional_photos.length + index"
                           v-for="(item, index) in images"
                           class="p-0 m-0 mx-1"
                           @click="selectMainPhoto($event)">

                        <span class="close" @click="deleteImage(index, item, true)"><b-img :src="require('@/assets/svg/circle-minus.svg')"/></span>
                        <b-img class="display-photo" style="width: 100%; height: 100%; object-fit: cover;" :src="preview(item)" @click="updatePhoto(item, true)"/>  
                    </b-col>

                    <!-- modra slika dodaj -->
                    <b-col cols="12" md="4" lg="3" v-if="additional_photos.length > 0 && additional_photos.length <= 20 || images.length > 0" class="d-flex justify-content-center align-items-center image-card cursor-pointer" style="background: #EAF3F9;" @dragover.prevent @drop.prevent @drop="filesChangeDrop" @click="$refs.fileInput.click()">
                        <b-img class="display-photo" style="width: 40px; height: 40px;" :src="require('@/assets/svg/plus.svg')"/>
                    </b-col>
                </b-row>
            </div>
            <div class="bottom-text">Oglasu lahko dodate do 20 fotografij. Podprti so formati .jpg, .jpeg, .gif, .png in .webp.</div>
        </div>
    </div>
</template>

<script>
    import {BRow, BCol, BImg} from 'bootstrap-vue'
    export default {
        components: {BRow, BCol, BImg},
        props: {
            additional_photos: {
                type: Array,
                required: true
            },
            main_photo: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                activeImage: null,
                imageFile: null,
                images: [],
                valid: true,
                localImage: false
            }
        },
        methods: {
            filesChange(e) {

                if (this.images.length >= 20) {
                    this.$printWarning('Maksimalno število slik je 20')
                    return
                }


                if (this.images.length === 0 && this.type !== 'edit') {
                    this.imageFile = e.target.files[0]
                } else if (this.images.length === 0 && this.additional_photos.length === 0 && this.type === 'edit') {
                    this.imageFile = e.target.files[0]
                    this.localImage = true
                }

                for (const file of e.target.files) {
                    if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/webp') this.images.push(file)
                    else this.$printWarning(`Datoteka ${ file.name } ni v pravem formatu`)
                   
                    if (this.images.length >= 20) {
                        this.$printWarning('Maksimalno število slik je 20')
                        break
                    }
                }
                this.$emit('update-preview-photos', this.imageFile, this.images)
            },
            filesChangeDrop(e) {
                if (this.images.length >= 20) {
                    this.$printWarning('Maksimalno število slik je 20')
                    return
                }


                if (this.images.length === 0 && this.type !== 'edit') {
                    this.imageFile = e.dataTransfer.files[0]
                } else if (this.images.length === 0 && this.additional_photos.length === 0 && this.type === 'edit') {
                    this.imageFile = e.dataTransfer.files[0]
                    this.localImage = true
                }

                for (const file of e.dataTransfer.files) {
                    if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/webp') this.images.push(file)
                    else this.$printWarning(`Datoteka ${ file.name } ni v pravem formatu`)

                    if (this.images.length >= 20) {
                        this.$printWarning('Maksimalno število slik je 20')
                        break
                    }
                }
                this.$emit('update-preview-photos', this.imageFile, this.images)
            },
            preview(item) {
                if (typeof item !== 'string') {
                    return URL.createObjectURL(item)
                }
            },
            selectMainPhoto(e) {
                this.activeImage = e
            },
            deleteImage(id, item, localImage) {
                if (localImage) this.images.splice(id, 1)
                else this.additional_photos.splice(id, 1)

                if (this.imageFile === item) {
                    if (this.additional_photos.length > 0) {
                        this.$emit('update-main-photo', this.additional_photos[0])
                        this.localImage = false
                        this.imageFile = this.additional_photos[0]
                    } else if (this.images.length > 0) {
                        this.localImage = true
                        this.imageFile = this.images[0]
                    } else {
                        this.imageFile = null
                    }
                }
                this.$emit('update-preview-photos', this.imageFile, this.images)
                this.$refs.fileInput.value = null
            },
            updatePhoto(item, localImage) {
                if (!localImage) this.$emit('update-main-photo', item)
                this.imageFile = item
                this.localImage = localImage
                this.$emit('update-preview-photos', this.imageFile, this.images)
            },
            async UploadImagesToAPI() {
                const thisIns = this

                const promises = []
                for (const image of this.images) {
                    const formData = new FormData()
                    formData.append('file', image)
                    const loadPromise = this.$http.post('/api/user/v1/image/', formData, {headers: {'Content-Type': 'multipart/form-data'}
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                        thisIns.valid = false
                    })
                    promises.push(loadPromise)
                }
                const results = await Promise.all(promises)
                let index = 0
                for (const res of results) {
                    if (res.status === 200) {
                        thisIns.additional_photos.push(res.data)
                        if (typeof thisIns.imageFile !== 'string' && thisIns.imageFile.name === thisIns.images[index++].name) {
                            thisIns.$emit('update-main-photo', res.data)
                        }
                    } else {
                        thisIns.valid = false
                    }
                }
                return this.valid
            }
        },
        watch: {
            main_photo(val) {
                if (this.main_photo !== '') this.imageFile = val
            }
        }
    }
</script>

<style scoped>
.bImg-img img {
  max-width: 100% !important;
}
.fileUp {
  border-radius: 0;
  background-color: #F6F6F6;
  padding-bottom: 20px;
}
.fileUpOut {
  margin: 1rem 1rem 1rem 1rem;
}
.card {
  background-color: unset;
  border-radius: 0;
}
.card-img-overlay {
  border-radius: 0;
}
.card-img {
  object-fit: contain;
  border-radius: 0;
  box-shadow: none !important;
}
.close {
  position: absolute;
  right: 7px;
  top: 7px;
  opacity: unset;
  cursor: pointer;
}
.tack {
  position: absolute;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
  background-color: #72A5D8;
  color: white;
}
.img-add {
  position: relative;
  display: inline-block;
}
.display-photo {
  cursor: pointer;
}
.input-add {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.bottom-text {
  position: absolute;
  left: 0;
  bottom: 10px;
  text-align: center;
  width: 100%;
  color: #848484;
  font-size: 13px;
  line-height: 19px;
}
.image-card {
  width: 187px;
  height: 118px;
  margin-right: 1rem;
  margin-bottom: 1rem !important;
  padding: 0 !important;
}
</style>